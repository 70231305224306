<template>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
      <v-card-title class="text-h5 text-center">Deseja realmente excluir esse item?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="closeDelete">Cancelar</v-btn>
        <v-btn color="green darken-1" text @click="deleteItemConfirm">Excluir</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>

    <loading-component :overlay="overlay"></loading-component>

  </v-dialog>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialogDelete',
    'sendEditedIndex',
  ],
  data: () => ({
    dialogDelete: false,
    editedIndex: 0,
    overlay: false,
  }),
  watch: {
    dialogDelete (val) {

      val || this.closeDelete()
    },
    sendDialogDelete (val) {

      this.dialogDelete = val
    },
    sendEditedIndex (val) {

      this.editedIndex = val
    },
  },
  methods: {
    deleteItemConfirm () {

      this.overlay = true

      model.apiDel('treinamento-professor/delete', this.editedIndex).then(response => {

        this.overlay = false

        this.message = response
        this.$emit('returnSnackbar', true)
        this.$emit('returnMessageDel', response.data)
        this.$emit('returnInitialize')

        this.closeDelete()
      })
    },
    closeDelete () {

      this.dialogDelete = false
      this.$emit('returnDialogDelete', false)

      this.$nextTick(() => {

        this.$emit('returnEditedIndex', 0)
      })
    },
  },
}
</script>
