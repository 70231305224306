
<template>
  <div>
    <v-row
        justify="center"
    >
      <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
      >
        <v-card tile>
          <v-toolbar
              flat
              dark
              color="secondary"
          >
            <v-toolbar-title class="ml-4">NetSchools</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="dialog2 = !dialog2"
              >
                Login
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="dialog2"
          max-width="500px"
      >
        <v-card>
          <v-card-title>
            Login
          </v-card-title>
          <v-card-text class="mt-10">

            <v-text-field
                label="Email"
                v-model="email"
                outlined
            ></v-text-field>

            <v-text-field
                label="Senha"
                v-model="password"
                type="password"
                outlined
            ></v-text-field>

          </v-card-text>
          <v-card-actions>
            <v-btn
                color="primary"
                text
                @click="changeLogin"
            >
              Entrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

import model from "@/modulos/model";
import session from "js-cookie"

export default {
  props: [
      //
  ],
  data: () => ({

    dialog: true,
    dialog2: false,
    email: '',
    password: '',
  }),
  watch: {

    //
  },
  methods: {

    close(){

      this.$router.go(0);
    },
    changeLogin(){

      model.apiLogin(this.email, this.password).then(response => {

        this.$emit('returnMessage', response)
        this.$emit('returnSnackbar', true)

        if(typeof response.sucesso != 'undefined'){

          session.set('_dialog', true)
          this.$emit('returnInitialize')
          this.close()
        }
      });
    }
  },
}
</script>
