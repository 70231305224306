export default {

    Run(){

        let footer = []

        footer.push([
            { content: 'ALEXSANDRO ALVES RAMOS\nInstrutor', styles: { halign: 'center', fontSize: 9, cellWidth: 100 }},
            { content: 'MÊNCIA BASTOS BONFIM\nCoordenadora', styles: { halign: 'center', fontSize: 9 }},
        ])

        return footer
    }
}
