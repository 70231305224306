<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">
          <v-autocomplete
              label="Alterar Ano"
              v-model="anos"
              :items="modelsFormCreate.anos"
              item-value="ano"
              item-text="ano"
              @change="changeAnos"
              outlined
          ></v-autocomplete>
        </v-toolbar>

        <v-toolbar flat class="mb-3">
          <v-text-field v-model="search" label="Buscar Candidato" class="mt-8" outlined append-icon="mdi-magnify"></v-text-field>
        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>CANDIDATOS</span><br>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-candidato-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendModelsFormCreate="modelsFormCreate"
              @returnDialog="dialog = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-create-candidato-component>

          <dialog-delete-candidato-component
              :sendEditedIndex="editedIndex"
              :sendDialogDelete="dialogDelete"
              @returnDialogDelete="dialogDelete = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessageDel="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-delete-candidato-component>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <router-link
            :to="{ name: 'geral-candidato-recurso', params: {id: item.id} }"
            style="text-decoration: none;"
            title="Julgamento de Recurso"
        >
          <v-icon small class="mr-2" color="orange" style=" font-size: 15px;">mdi-scale-balance</v-icon>
        </router-link>

<!--        <v-icon small class="mr-2" @click="recursoItem(item)" title="Julgamento de recurso">mdi-scale-balance</v-icon>-->
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>

      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>
    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateCandidatoComponent from "@/components/geral/candidatos/DialogCreateCandidatoComponent.vue";
import DialogDeleteCandidatoComponent from "@/components/geral/candidatos/DialogDeleteCandidatoComponent.vue";

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateCandidatoComponent,
    DialogDeleteCandidatoComponent,
  },
  data: () => ({

    dialog: false,
    dialogDelete: false,
    anos: '',
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      { text: 'Inscricao', align: 'center', sortable: false, value: 'inscricao' },
      { text: 'Data', align: 'center', sortable: false, value: 'data_inscricao' },
      { text: 'Horario', align: 'center', sortable: false, value: 'horario_inscricao' },
      { text: 'Nome', align: 'start', sortable: false, value: 'nome' },
      { text: 'Data de nascimento', align: 'start', sortable: false, value: 'data_nascimento' },
      { text: 'Cargo', align: 'start', sortable: false, value: 'cargo' },
      { text: 'Experiência', align: 'center', sortable: false, value: 'titulo_experiencia_profissional' },
      { text: 'Idade', align: 'center', sortable: false, value: 'idade' },
      { text: 'Situação', align: 'start', sortable: false, value: 'situacao' },
      { text: 'Total de pontos', align: 'center', sortable: false, value: 'pontos' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
    ],
    editedItem: {
      descricao: '',
    },
    defaultItem: {
      descricao: '',
    },
    desserts: [],
    editedIndex: 0,
    modelsFormCreate: [],
  }),
  created() {

    this.initialize()
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('candidato').then(response => {

        this.overlay = false

        this.desserts = response.data.candidatos
        this.modelsFormCreate = response.data.modelos
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {

      this.editedIndex = item.id
      this.dialogDelete = true
    },
    changeAnos(val){

      this.overlay = true

      model.apiPut('anos/update', 1, {ano: val}).then(response => {

        this.$emit('returnAno', response.data.ano)

        this.overlay = false
        this.snackbar = true
        this.message.sucesso = `Ano atualizado para ${response.data.ano}`

        this.initialize()
      })
    }
  },
}
</script>

<style scoped>

</style>
