<template>
<div>
  <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="(message.erro ? 'error' : 'success')">
    {{ message.erro ? message.erro : message.sucesso }}
    <template v-slot:action="{ attrs }">
      <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
        Fechar
      </v-btn>
    </template>
  </v-snackbar>
</div>
</template>

<script>
export default {
  props: [
      'message',
      'sendSnackbar',
  ],
  data: () => ({
    snackbar: false,
    multiLine: true,
  }),
  created() {

    //
  },
  computed: {

    //
  },
  watch: {

    sendSnackbar (val) {

      this.snackbar = val
    },
    snackbar (val) {

      this.$emit('returnSnackbar', val)
    },
  },
}
</script>

<style scoped>

</style>
