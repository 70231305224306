import session from 'js-cookie'

export default{

    auth(to, from, next){

        const token = session.get('_token')

        if(!token){

            next('/')
        }

        next()
    }
}
