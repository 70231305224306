<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">
          <v-text-field v-model="search" label="Search" class="mt-8" outlined append-icon="mdi-magnify"></v-text-field>
        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>TREINAMENTO DOS PROFESSORES</span><br>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-treinamento-professor-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendModelsFormCreate="modelsFormCreate"
              @returnDialog="dialog = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-create-treinamento-professor-component>

          <dialog-delete-treinamento-professor-component
              :sendEditedIndex="editedIndex"
              :sendDialogDelete="dialogDelete"
              @returnDialogDelete="dialogDelete = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessageDel="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-delete-treinamento-professor-component>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

<!--        <router-link-->
<!--            :to="{ name: 'geral-candidato-recurso', params: {id: item.id} }"-->
<!--            style="text-decoration: none;"-->
<!--            title="Julgamento de Recurso"-->
<!--        >-->
<!--          <v-icon small class="mr-2" color="orange" style=" font-size: 15px;">mdi-scale-balance</v-icon>-->
<!--        </router-link>-->

        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>

      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>
    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateTreinamentoProfessorComponent from "@/components/geral/treinamentos-professores/DialogCreateTreinamentoProfessorComponent.vue";
import DialogDeleteTreinamentoProfessorComponent from "@/components/geral/treinamentos-professores/DialogDeleteTreinamentoProfessorComponent.vue";

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateTreinamentoProfessorComponent,
    DialogDeleteTreinamentoProfessorComponent,
  },
  data: () => ({

    dialog: false,
    dialogDelete: false,
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      { text: 'Nome', align: 'start', sortable: false, value: 'nome' },
      { text: 'Cpf', align: 'start', sortable: false, value: 'cpf' },
      { text: 'Celular', align: 'start', sortable: false, value: 'celular' },
      { text: 'Data do treinamento', align: 'center', sortable: false, value: 'data_treinamento' },
      { text: 'Dia da semana', align: 'start', sortable: false, value: 'dia_semana' },
      { text: 'Turno', align: 'start', sortable: false, value: 'textTurno' },
      { text: 'Ausente', align: 'start', sortable: false, value: 'textAusente' },
      { text: 'Ativo', align: 'start', sortable: false, value: 'textAtivo' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
    ],
    editedItem: {
      descricao: '',
    },
    defaultItem: {
      descricao: '',
    },
    desserts: [],
    editedIndex: 0,
    modelsFormCreate: [],
  }),
  created() {

    this.initialize()
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('treinamento-professor').then(response => {

        this.overlay = false

        this.desserts = response.data.professores
        this.modelsFormCreate = response.data.modelos
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {

      this.editedIndex = item.id
      this.dialogDelete = true
    },
  },
}
</script>

<style scoped>

</style>
