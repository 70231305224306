<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        sort-by="nome"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">
          <v-text-field v-model="search" label="Search" class="mt-8" outlined append-icon="mdi-magnify"></v-text-field>
        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>RELATÓRIOS</span><br>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-relatorio-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendModelsFormCreate="modelsFormCreate"
              @returnDialog="dialog = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
              @returnAdmin="admin = $event"
          ></dialog-create-relatorio-component>

          <dialog-delete-relatorio-component
              :sendEditedIndex="editedIndex"
              :sendDialogDelete="dialogDelete"
              @returnDialogDelete="dialogDelete = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessageDel="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-delete-relatorio-component>
        </v-toolbar>

        <v-toolbar flat>
          <template>
            <v-row justify="center">
              <v-dialog
                  v-model="dialogRelatorio"
                  persistent
                  width="500"
              >
                <v-card>

                  <v-card-title>
                    <span class="text-h5">{{ itemRelatorio.descricao }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row dense>

                        <v-col cols="12" v-for="(item, index) in dadosRelatorio" :key="index">
                          <v-autocomplete
                              v-if="item.modelo == 'combo_1'"
                              :label="item.label"
                              v-model="combo_1"
                              :items="item.dados"
                              item-value="id"
                              item-text="descricao"
                              outlined
                          >
                          </v-autocomplete>

                          <v-autocomplete
                              v-if="item.modelo == 'combo_2'"
                              :label="item.label"
                              v-model="combo_2"
                              :items="item.dados"
                              item-value="id"
                              item-text="descricao"
                              outlined
                          >
                          </v-autocomplete>

                          <v-text-field
                              v-if="item.modelo == 'data_1'"
                              :label="item.label"
                              v-model="data_1"
                              v-mask="'##/##/####'"
                              outlined
                          ></v-text-field>

                          <v-text-field
                              v-if="item.modelo == 'data_2'"
                              :label="item.label"
                              v-model="data_2"
                              v-mask="'##/##/####'"
                              outlined
                          ></v-text-field>

                        </v-col>

                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-col cols="12" class="text-right">
                      <v-btn color="red darken-1" text @click="dialogRelatorio = false">
                        Fechar
                      </v-btn>
                      <v-btn color="green darken-1" text @click="gerarPdf">
                        Gerar
                      </v-btn>
                    </v-col>
                  </v-card-actions>

                </v-card>
              </v-dialog>
            </v-row>
          </template>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon small class="mr-2" @click="gerarRelatorio(item)" title="Gerar Relatório">mdi-file</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)" v-if="admin == 1">mdi-delete</v-icon>

      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>

    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateRelatorioComponent from "@/components/geral/relatorios/DialogCreateRelatorioComponent.vue";
import DialogDeleteRelatorioComponent from "@/components/geral/relatorios/DialogDeleteRelatorioComponent.vue";
import session from "js-cookie";
import relClassificadosPorCargo from "@/relatorios/geral/classificados-por-cargo"
import relRelacaoDeInscritos from "@/relatorios/geral/relacao-de-inscritos"
import relCandidatosPorCampo from "@/relatorios/geral/candidatos-por-campo"
import relCassificacaoGeralPorCargo from "@/relatorios/geral/classificacao-geral-por-cargo"
import relResultaDosRecursosInterpostos from "@/relatorios/geral/resultado-dos-recursos-interpostos"
import relListaDePresenca from "@/relatorios/geral/lista-de-presenca"
import relTreinamentoCronograma from "@/relatorios/geral/treinamento-cronograma"

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateRelatorioComponent,
    DialogDeleteRelatorioComponent,
  },
  data: () => ({

    dialog: false,
    dialogDelete: false,
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      { text: 'Descrição', align: 'start', sortable: false, value: 'descricao' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
    ],
    editedItem: {},
    //Models
    combo_1: '',
    combo_2: '',
    data_1: '',
    data_2: '',
    //Fim
    desserts: [],
    editedIndex: 0,
    modelsFormCreate: [],
    nivelId: session.get('_nivel_id'),
    admin: session.get('_admin'),
    //Relatório
    dialogRelatorio: false,
    itemRelatorio: {},
    dadosRelatorio: [],
    dadosPdf: {},
  }),
  created() {

    this.initialize()
  },
  watch: {

    admin(val) { this.$emit('returnAdmin', val) }
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('relatorio').then(response => {

        this.overlay = false

        this.desserts = response.data.relatorios
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {

      this.editedIndex = item.id
      this.dialogDelete = true
    },
    gerarRelatorio(item){

      this.itemRelatorio = item

      model.apiGet('relatorios', item.id).then(response => {

        this.dadosRelatorio = response.data
      })

      this.dialogRelatorio = true
    },
    gerarPdf(){

      this.overlay = true

      let params = {}

      if(this.combo_1 != ''){

        params.combo_1 = this.combo_1
      }

      if(this.combo_2 != ''){

        params.combo_2 = this.combo_2
      }

      if(this.data_1 != ''){

        params.data_1 = this.data_1
      }

      if(this.data_2 != ''){

        params.data_2 = this.data_2
      }

      this.dadosPdf = {
        id: this.itemRelatorio.id,
        descricao: this.itemRelatorio.descricao,
        params: params,
      }

      let link = this.itemRelatorio.link

      model.apiPost(link, this.dadosPdf).then(response => {

        if(response.chave == 'classificados_por_cargo' && this.admin > 0){

          relClassificadosPorCargo.run(response)
        }
        else if(response.chave == 'relacao_de_inscritos' && this.admin > 0){

          relRelacaoDeInscritos.run(response)
        }
        else if(response.chave == 'candidatos_por_campo' && this.admin > 0){

          relCandidatosPorCampo.run(response)
        }
        else if(response.chave == 'classificacao_geral_por_cargo' && this.admin > 0){

          relCassificacaoGeralPorCargo.run(response)
        }
        else if(response.chave == 'resultado_dos_recursos_interpostos' && this.admin > 0){

          relResultaDosRecursosInterpostos.run(response)
        }
        else if(response.chave == 'lista_de_presenca' && this.admin > 0){

          relListaDePresenca.run(response)
        }
        else if(response.chave == 'treinamento_cronograma'){

          if(response.erro){

            this.message = response
            this.snackbar = true;
          }
          else {

            relTreinamentoCronograma.run(response)
          }
        }

        this.overlay = false

      })
    },
  },
}
</script>

<style scoped>

</style>
