import jsPDF from "jspdf"
import 'jspdf-autotable'
import cabecalho from '@/relatorios/geral/header'

export default {

    run(item){

        let header = []
        header.push(cabecalho.run())

        let relatorio = []

        relatorio.push([{ content: item.descricao, colSpan: 6, styles: { halign: 'center', valign: 'middle' }}])

        relatorio.push(
            [
                { content: item.linha_ampla[0], colSpan: 3, styles: { halign: 'center', valign: 'middle' }},
                { content: item.linha_ampla[1], styles: { halign: 'center' }},
                { content: item.linha_ampla[2], colSpan: 2, styles: { halign: 'center' }},
            ])

        relatorio.push(
            [
                { content: item.titulos[0], styles: { halign: 'center' }},
                { content: item.titulos[1], styles: { halign: 'center' }},
                { content: item.titulos[2], styles: { halign: 'center' }},
                { content: item.titulos[3], styles: { halign: 'center' }},
                { content: item.titulos[4], styles: { halign: 'center' }},
                { content: item.titulos[5], styles: { halign: 'center' }},
            ])

        if(item.ampla.length > 0)
        {
            for(let i = 0; i < item.ampla.length; i++){

                relatorio.push(
                    [
                        { content: item.ampla[i]['ordem'], styles: { halign: 'center' }},
                        { content: item.ampla[i]['inscricao'], styles: { halign: 'center' }},
                        { content: item.ampla[i]['nome'], styles: { halign: 'left' }},
                        { content: item.ampla[i]['total'], styles: { halign: 'center' }},
                        { content: item.ampla[i]['titulo_experiencia_profissional'], styles: { halign: 'center' }},
                        { content: item.ampla[i]['idade'], styles: { halign: 'center' }},
                    ])
            }
        }

        relatorio.push([{ content: ' ', colSpan: 6, styles: { halign: 'center', valign: 'middle' }}])

        relatorio.push(
            [
                { content: item.linha_reserva[0], colSpan: 3, styles: { halign: 'center', valign: 'middle' }},
                { content: item.linha_reserva[1], styles: { halign: 'center' }},
                { content: item.linha_reserva[2], colSpan: 2, styles: { halign: 'center' }},
            ])

        relatorio.push(
            [
                { content: item.titulos[0], styles: { halign: 'center' }},
                { content: item.titulos[1], styles: { halign: 'center' }},
                { content: item.titulos[2], styles: { halign: 'center' }},
                { content: item.titulos[3], styles: { halign: 'center' }},
                { content: item.titulos[4], styles: { halign: 'center' }},
                { content: item.titulos[5], styles: { halign: 'center' }},
            ])

        if(item.reserva.length > 0)
        {
            for(let j = 0; j < item.reserva.length; j++){

                relatorio.push(
                    [
                        { content: item.reserva[j]['ordem'], styles: { halign: 'center' }},
                        { content: item.reserva[j]['inscricao'], styles: { halign: 'center' }},
                        { content: item.reserva[j]['nome'], styles: { halign: 'left' }},
                        { content: item.reserva[j]['total'], styles: { halign: 'center' }},
                        { content: item.reserva[j]['titulo_experiencia_profissional'], styles: { halign: 'center' }},
                        { content: item.reserva[j]['idade'], styles: { halign: 'center' }},
                    ])
            }
        }

        relatorio.push([{ content: ' ', colSpan: 6, styles: { halign: 'center', valign: 'middle' }}])

        relatorio.push(
            [
                { content: item.linha_pcd[0], colSpan: 3, styles: { halign: 'center', valign: 'middle' }},
                { content: item.linha_pcd[1], styles: { halign: 'center' }},
                { content: item.linha_pcd[2], colSpan: 2, styles: { halign: 'center' }},
            ])

        relatorio.push(
            [
                { content: item.titulos[0], styles: { halign: 'center' }},
                { content: item.titulos[1], styles: { halign: 'center' }},
                { content: item.titulos[2], styles: { halign: 'center' }},
                { content: item.titulos[3], styles: { halign: 'center' }},
                { content: item.titulos[4], styles: { halign: 'center' }},
                { content: item.titulos[5], styles: { halign: 'center' }},
            ])

        if(item.pcd.length > 0)
        {
            for(let k = 0; k < item.pcd.length; k++){

                relatorio.push(
                    [
                        { content: item.pcd[k]['ordem'], styles: { halign: 'center' }},
                        { content: item.pcd[k]['inscricao'], styles: { halign: 'center' }},
                        { content: item.pcd[k]['nome'], styles: { halign: 'left' }},
                        { content: item.pcd[k]['total'], styles: { halign: 'center' }},
                        { content: item.pcd[k]['titulo_experiencia_profissional'], styles: { halign: 'center' }},
                        { content: item.pcd[k]['idade'], styles: { halign: 'center' }},
                    ])
            }
        }

        const doc = new jsPDF()

        //Tabela do cabeçalho
        doc.autoTable({theme: 'plain', body: header, didDrawCell: (data) => {
                if (data.section === 'body' && data.column.index === 0) {
                    var base64Img = cabecalho.logo()
                    doc.addImage(base64Img, 'JPEG', data.cell.x + 3, data.cell.y, 30, 20)
                }
            },})

        // doc.autoTable({theme: 'plain', body: cabecalho})
        doc.autoTable({theme: 'grid', body: relatorio})

        doc.output('dataurlnewwindow')
    },
}
