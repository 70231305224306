<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Inscrição
            </v-btn>

            <v-btn color="success" dark class="ml-3" large @click="openDialogPiso">
              <v-icon>mdi-plus</v-icon>
              Piso Salarial
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#dados-gerais">DADOS GERAIS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="dados-gerais" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="12" md="8">
                  <v-text-field
                      v-model="editedItem.servidor"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Servidor
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.admissao"
                      v-mask="'##/##/####'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Data de admissão
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.graduacao" label="Graduação" color="indigo" hide-details></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.especializacao" label="Especialização" color="indigo" hide-details></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.mestrado" label="Mestrado" color="indigo" hide-details></v-switch>
                </v-col>
                <v-col cols="12" md="3" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.doutorado" label="Doutorado" color="indigo" hide-details></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                      label="Avaliação de desempenho"
                      v-model="editedItem.avaliacao_de_desempenho"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      v-model="editedItem.carga_horaria"
                      v-mask="'##'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Carga horária
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-autocomplete
                            v-model="editedItem.modalidades_de_ensino"
                            :items="modelsFormCreate.modalidades_de_ensino"
                            item-value="id"
                            item-text="descricao"
                            outlined
                    >
                        <template #label>
                            <span class="red--text"><strong>* </strong></span>Modalidades de Ensino
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Classe Atual"
                      v-model="editedItem.classe_atual"
                      :items="modelsFormCreate.classes"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.em_sala_de_aula" label="Em sala de aula" color="indigo" hide-details @change="changeEmSalaDeAula"></v-switch>
                </v-col>
                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.educacao_infantil_fundamental_1" label="Atua na Educação Infantil / Fundamental I" color="indigo" hide-details></v-switch>
                </v-col>
                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.zona_rural" label="Atua na zona rural" color="indigo" hide-details></v-switch>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Função"
                      v-model="editedItem.funcao"
                      :items="modelsFormCreate.funcoes"
                      item-value="id"
                      item-text="descricao"
                      outlined
                      :disabled="editedItem.em_sala_de_aula == 1 ? true : false"
                      @change="changeFuncao"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Grati-Função (%)"
                      v-model="editedItem.percent_grati_funcao"
                      v-mask="'##'"
                      outlined
                      :disabled="editedItem.funcao != 'DEDICACAO_EXCLUSIVA' ? true : false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      v-model="editedItem.escolaridade"
                      :items="modelsFormCreate.escolaridades"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Escolaridade
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                    <v-autocomplete
                      label="Estabilidade"
                      v-model="editedItem.estabilidade"
                      :items="modelsFormCreate.estabilidades"
                      item-value="id"
                      item-text="descricao"
                      outlined
                    ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field
                      label="Salário Família"
                      v-model="editedItem.salario_familia"
                      v-money="{precision: 2}"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Giac 80/119 (2,5%)"
                      v-model="editedItem.giac_80_119_qtd"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Giac 120/359 (5%)"
                      v-model="editedItem.giac_120_359_qtd"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Giac 360 (7,5%)"
                      v-model="editedItem.giac_360_qtd"
                      v-mask="'##'"
                      outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Tipo do contrato"
                      v-model="editedItem.tipo_contrato"
                      :items="modelsFormCreate.tipos_contratos"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Cargo"
                      v-model="editedItem.cargo"
                      :items="modelsFormCreate.cargos"
                      item-value="id"
                      item-text="descricao"
                      outlined
                      @change="changeCargo"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      :label="!cargo ? 'Unidade de Ensino (Portes)' : ''"
                      v-model="editedItem.unidade_ensino"
                      :items="modelsFormCreate.portes"
                      item-value="id"
                      item-text="descricao"
                      outlined
                      :disabled="!cargo ? true : false"
                  >
                    <template #label v-if="cargo">
                      <span class="red--text"><strong>* </strong></span>Unidade de Ensino (Portes)
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      label="Quinquênio Extra (%)"
                      v-model="editedItem.quinquenio_extra"
                      v-mask="'###'"
                      outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.jornada_diferenciada" label="Jornada diferenciada" color="indigo" hide-details></v-switch>
                </v-col>
                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.grati_nee" label="Grati NEE" color="indigo" hide-details></v-switch>
                </v-col>
                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch v-model="editedItem.ativo" label="Ativo" color="indigo" hide-details></v-switch>
                </v-col>

                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch
                      v-model="editedItem.aperfeicoamento10"
                      label="Aperfeiçoamento 10%"
                      color="indigo"
                      hide-details
                      @change="changeAperf10"></v-switch>
                </v-col>
                <v-col cols="12" md="4" class="mb-5" style="margin-top: -25px;">
                  <v-switch
                      v-model="editedItem.aperfeicoamento20"
                      label="Aperfeiçoamento 20%"
                      color="indigo"
                      hide-details
                      @change="changeAperf20"></v-switch>
                </v-col>

              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendModelsFormCreate',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      servidor: '',
      admissao: '',
      graduacao: '',
      especializacao: '',
      mestrado: '',
      doutorado: '',
      avaliacao_de_desempenho: '',
      carga_horaria: '',
      modalidades_de_ensino: '',
      classe_atual: '',
      em_sala_de_aula: '',
      educacao_infantil_fundamental_1: '',
      funcao: '',
      percent_grati_funcao: '',
      escolaridade: '',
      estabilidade: '',
      salario_familia: '',
      zona_rural: '',
      giac_80_119_qtd: '',
      giac_120_359_qtd: '',
      giac_360_qtd: '',
      tipo_contrato: '',
      cargo: '',
      unidade_ensino: '',
      quinquenio_extra: '',
      jornada_diferenciada: '',
      grati_nee: '',
      aperfeicoamento10: '',
      aperfeicoamento20: '',
      ativo: true,
    },
    defaultItem: {
      servidor: '',
      admissao: '',
      graduacao: false,
      especializacao: false,
      mestrado: false,
      doutorado: false,
      avaliacao_de_desempenho: '',
      carga_horaria: '',
      modalidades_de_ensino: '',
      classe_atual: '',
      em_sala_de_aula: '',
      educacao_infantil_fundamental_1: '',
      funcao: '',
      percent_grati_funcao: '',
      escolaridade: '',
      estabilidade: '',
      salario_familia: '',
      zona_rural: '',
      giac_80_119: '',
      giac_120_359: '',
      giac_360: '',
      tipo_contrato: '',
      cargo: '',
      unidade_ensino: '',
      quinquenio_extra: '',
      jornada_diferenciada: '',
      grati_nee: '',
      aperfeicoamento10: '',
      aperfeicoamento20: '',
      ativo: true,
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    cargo: false,
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Inscrição'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) { this.editedItem = val; this.ausente = val.ausente; this.changeCargo(val.cargo) },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        model.apiPut('calculo-remuneracao-professor/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        model.apiPost('calculo-remuneracao-professor/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
    changeAperf10(val){

      if(val){

        this.editedItem.aperfeicoamento20 = false;
      }
    },
    changeAperf20(val){

      if(val){

        this.editedItem.aperfeicoamento10 = false;
      }
    },
    changeCargo(val){

      if(val == 'DIRETOR' || val == 'VICE_DIRETOR'){

        this.cargo = true;
      }
      else{

        this.cargo = false;
        this.editedItem.unidade_ensino = ''
      }
    },
    changeEmSalaDeAula(val){

      if(val){
        this.editedItem.funcao = null
        this.editedItem.percent_grati_funcao = null
      }
    },
    changeFuncao(val){

      if(val != 'DEDICACAO_EXCLUSIVA'){
        this.editedItem.percent_grati_funcao = null
      }
    },
    openDialogPiso(){

      this.$emit('returnDialogPiso', true)
    }
  },
}
</script>
