<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">

          <v-text-field v-model="search" label="Search" class="mt-8" outlined append-icon="mdi-magnify" style="margin-right: 20px;"></v-text-field>

          <template>
            <v-card class="mx-auto my-8" max-width="344" elevation="16">
              <v-card-item>
                <v-card-title>{{ totalRemuneracao }}</v-card-title>
                <v-card-subtitle>Total da Remuneração</v-card-subtitle>
              </v-card-item>
            </v-card>
          </template>

        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>REMUNERAÇÃO DOS PROFESSORES</span><br>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-remuneracao-professor-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendModelsFormCreate="modelsFormCreate"
              @returnDialog="dialog = $event"
              @returnDialogPiso="dialogPisoSalarial = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-create-remuneracao-professor-component>

          <v-dialog v-model="dialogPisoSalarial" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 text-center">Piso Salarial</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                        v-model="pisoSalarial"
                        v-money="{precision: 2}"
                        outlined
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span>Valor
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="closeDialogPiso">Fechar</v-btn>
                <v-btn color="green darken-1" text @click="saveDialogPiso">Salvar</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon small class="mr-2" @click="relatorio(item.id)">mdi-eye</v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>

      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>
    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateRemuneracaoProfessorComponent from "@/components/geral/remuneracoes-professores/DialogCreateRemuneracaoProfessorComponent.vue";
import relRemuneracaoDados from "@/relatorios/geral/remuneracao-dados"

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateRemuneracaoProfessorComponent,
  },
  data: () => ({

    dialog: false,
    dialogPisoSalarial: false,
    pisoSalarial: '',
    totalRemuneracao: '',
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      { text: 'Servidor', align: 'start', sortable: false, value: 'servidor' },
      { text: 'Admissão', align: 'center', sortable: false, value: 'admissao' },
      { text: 'Carga Horária', align: 'center', sortable: false, value: 'carga_horaria' },
      { text: 'Tempo/serviço (anos)', align: 'center', sortable: false, value: 'tempo_servico' },
      { text: 'Piso (R$)', align: 'center', sortable: false, value: 'piso' },
      { text: 'Remuneração (R$)', align: 'center', sortable: false, value: 'remuneracao' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
    ],
    editedItem: {
      descricao: '',
    },
    defaultItem: {
      descricao: '',
    },
    desserts: [],
    editedIndex: 0,
    modelsFormCreate: [],
  }),
  created() {

    this.initialize()
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('calculo-remuneracao-professor').then(response => {

        this.overlay = false

        this.totalRemuneracao = response.data.total
        this.desserts = response.data.remuneracoes
        this.modelsFormCreate = response.data.modelos
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    relatorio(id){

      model.apiGet('calculo-remuneracao-professor/relatorio', id).then(response => {

        relRemuneracaoDados.Run(response.data)
      })
    },
    saveDialogPiso(){

      this.overlay = true

      model.apiPost('calculo-remuneracao-professor/piso-salarial', {valor: this.pisoSalarial}).then(response => {

        this.initialize()

        this.overlay = false;
        this.message = response
        this.snackbar = true;
      })

      this.pisoSalarial = ''
      this.dialogPisoSalarial = false
    },
    closeDialogPiso(){

      this.pisoSalarial = ''
      this.dialogPisoSalarial = false
    }
  },
}
</script>

<style scoped>

</style>
