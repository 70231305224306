<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Candidato
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#dados-gerais">DADOS GERAIS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="dados-gerais" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.inscricao"
                      outlined
                      :readonly="true"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Inscrição
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.data_inscricao"
                      v-mask="'##/##/####'"
                      outlined
                      @change="geraInscricao"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Data de inscrição
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.horario_inscricao"
                      v-mask="'##:##'"
                      outlined
                      @change="geraInscricao"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Horário de inscrição
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="8">
                  <v-text-field
                      v-model="editedItem.nome"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Nome
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      label="Data de nascimento"
                      v-model="editedItem.data_nascimento"
                      v-mask="'##/##/####'"
                      outlined
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                      label="Cargo"
                      v-model="editedItem.cargo"
                      :items="modelsFormCreate.cargos"
                      item-value="sigla"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="RG"
                      v-model="editedItem.rg"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="CPF"
                      v-model="editedItem.cpf"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="CTPS"
                      v-model="editedItem.ctps"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="CRN (inativo)"
                      v-model="editedItem.crn"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      :disabled="true"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Comprovante de residência"
                      v-model="editedItem.comprovante_residencia"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Título de eleitor"
                      v-model="editedItem.titulo_eleitor"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Quitação eleitoral"
                      v-model="editedItem.quitacao_eleitoral"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Reservista"
                      v-model="editedItem.reservista"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-autocomplete
                      label="Diploma"
                      v-model="editedItem.diploma"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                      label="Histórico acadêmico"
                      v-model="editedItem.historico_academico"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                      label="Currículo"
                      v-model="editedItem.curriculo"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                      label="Título de experiência profissional"
                      v-model="editedItem.titulo_experiencia_profissional"
                      v-mask="'#,##'"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      label="Título acadêmico"
                      v-model="editedItem.titulo_academico"
                      v-mask="'#,##'"
                      outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      label="Outros títulos"
                      v-model="editedItem.titulo_outros"
                      v-mask="'#,##'"
                      outlined
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Formulário de inscrição"
                      v-model="editedItem.formulario_inscricao"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Declaração de veracidade"
                      v-model="editedItem.declaracao_veracidade"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Declaração de pessoa com deficiência"
                      v-model="editedItem.declaracao_pessoa_com_deficiencia"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete
                      label="Situacão"
                      v-model="editedItem.situacao"
                      :items="modelsFormCreate.situacaos"
                      item-value="id"
                      item-text="descricao"
                      outlined
                      @change="changeSituacao"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                      label="Histórico acadêmico (LIBRAS)"
                      v-model="editedItem.historico_academico_libras"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                      label="Exame de proeficiência"
                      v-model="editedItem.exame_de_proeficiencia"
                      :items="modelsFormCreate.simNao"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      :label="deferido ? 'Observação' : ''"
                      v-model="editedItem.observacao"
                      outlined
                  >
                    <template #label v-if="!deferido">
                      <span class="red--text"><strong>* </strong></span>Observação
                    </template>
                  </v-text-field>
                </v-col>

              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendModelsFormCreate',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      inscricao: '',
      data_inscricao: '00/00/0000',
      horario_inscricao: '00:00',
      nome: '',
      cargo: '',
      rg: '',
      cpf: '',
      ctps: '',
      crn: '',
      comprovante_residencia: '',
      titulo_eleitor: '',
      quitacao_eleitoral: '',
      reservista: '',
      diploma: '',
      historico_academico: '',
      curriculo: '',
      titulo_experiencia_profissional: '',
      titulo_academico: '',
      titulo_outros: '',
      formulario_inscricao: '',
      declaracao_veracidade: '',
      declaracao_pessoa_com_deficiencia: '',
      observacao: '',
      situacao: '',
      data_nascimento: '',
      historico_academico_libras: '',
      exame_de_proeficiencia: ''
    },
    defaultItem: {
      inscricao: '',
      data_inscricao: '',
      horario_inscricao: '',
      nome: '',
      cargo: '',
      rg: '',
      cpf: '',
      ctps: '',
      crn: '',
      comprovante_residencia: '',
      titulo_eleitor: '',
      quitacao_eleitoral: '',
      reservista: '',
      diploma: '',
      historico_academico: '',
      curriculo: '',
      titulo_experiencia_profissional: '',
      titulo_academico: '',
      titulo_outros: '',
      formulario_inscricao: '',
      declaracao_veracidade: '',
      declaracao_pessoa_com_deficiencia: '',
      observacao: '',
      situacao: '',
      data_nascimento: '',
      historico_academico_libras: '',
      exame_de_proeficiencia: ''
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    deferido: false,
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Candidato'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) { this.editedItem = val; this.deferido = (val.situacao == 'DEF' ? true : false) },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        model.apiPut('candidato/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        model.apiPost('candidato/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
    changeSituacao(val){

      if(val == 'DEF'){

        this.deferido = true
      }
      else{

        this.deferido = false
      }
    },
    geraInscricao(){

      let data = this.editedItem.data_inscricao.split('/')
      let hora = this.editedItem.horario_inscricao.split(':')

      this.editedItem.inscricao = data[2] + data[1] + data[0] + hora[0] + hora[1]
    },
  },
}
</script>
