<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }" v-if="admin == 1">
        <v-row>
          <v-col cols="12">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Usuário
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#informacoes-gerais">INFORMAÇÕES GERAIS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="informacoes-gerais" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="12">
                  <v-text-field
                      v-model="editedItem.name"
                      outlined
                      :disabled="editedIndex > 0 ? true : false"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Nome
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="editedItem.email"
                      outlined
                      :disabled="editedIndex > 0 ? true : false"
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Email
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      label="Senha"
                      v-model="editedItem.password"
                      outlined
                      type="password"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" v-if="editedIndex <= 0">
                  <v-autocomplete
                      v-model="editedItem.nivel"
                      :items="modelsFormCreate.niveis"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Nível
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--Fim-->

                <v-col cols="12" v-if="admin == 1">
                  <v-switch v-model="editedItem.admin" label="Administrador" color="indigo" hide-details></v-switch>
                </v-col>

              </v-row>
            </v-tab-item>

          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import session from "js-cookie";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendModelsFormCreate',
    'sendAdmin',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      name: '',
      email: '',
      password: '',
      admin: false,
      estabelecimento_id: '',
      nivel: '',
    },
    defaultItem: {
      name: '',
      email: '',
      password: '',
      admin: false,
      estabelecimento_id: '',
      nivel: '',
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    admin: session.get('_admin'),
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Usuário'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) {this.editedItem = val },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },

    sendAdmin (val) { this.admin = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        model.apiPut('users/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            if(this.editedIndex == session.get('_usuario_id'))
            {
              this.setAdmin()
            }

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        model.apiPost('users/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
    setAdmin(){

      model.apiGet('set-admin', this.editedIndex).then(response => {

        session.set('_admin', response.data.admin)
        this.$emit('returnAdmin', response.data.admin)
      })
    },
  },
}
</script>
