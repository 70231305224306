//Middlewares
import guard from '@/middleware/authentication'
//Sistema
import HomeComponent from './../components/home/HomeComponent'
import GeralMenuComponent from '../components/geral/GeralMenuComponent'
import GeralUsuarioComponent from '../components/geral/usuarios/GeralUsuarioComponent.vue'
import GeralCandidatoComponent from "@/components/geral/candidatos/GeralCandidatoComponent.vue";
import GeralCandidatoRecursoComponent from "@/components/geral/candidatos-recursos/GeralCandidatoRecursoComponent.vue";
import GeralTreinamentoProfessorComponent from "@/components/geral/treinamentos-professores/GeralTreinamentoProfessorComponent.vue";
import GeralRemuneracaoProfessorComponent from "@/components/geral/remuneracoes-professores/GeralRemuneracaoProfessorComponent.vue";
import GeralRelatorioComponent from "@/components/geral/relatorios/GeralRelatorioComponent.vue";

export default[
    //Home
    {path: '/', name: 'home', component: HomeComponent},
    //Geral
    {path: '/geral-menu', name: 'geral-menu', component: GeralMenuComponent, beforeEnter:guard.auth},
    {path: '/geral-usuario', name: 'geral-usuario', component: GeralUsuarioComponent, beforeEnter:guard.auth},
    {path: '/geral-candidato', name: 'geral-candidato', component: GeralCandidatoComponent, beforeEnter:guard.auth},
    {path: '/geral-candidato-recurso/:id', name: 'geral-candidato-recurso', component: GeralCandidatoRecursoComponent, beforeEnter:guard.auth},
    {path: '/geral-treinamento-professor', name: 'geral-treinamento-professor', component: GeralTreinamentoProfessorComponent, beforeEnter:guard.auth},
    {path: '/geral-remuneracao-professor', name: 'geral-remuneracao-professor', component: GeralRemuneracaoProfessorComponent, beforeEnter:guard.auth},
    {path: '/geral-relatorio', name: 'geral-relatorio', component: GeralRelatorioComponent, beforeEnter:guard.auth},
]
