<template>
  <v-card
      class="card-geral mx-auto"
  >
    <v-container>
      <v-row dense class="py-5">

        <v-col
            v-for="(item, i) in items"
            :key="i"
            cols="12"
            md="6"
        >
          <v-card
              style="background-color: beige;"
              dark
          >
            <router-link :to="{ name: item.link }" style="text-decoration: none;" v-if="item.visible == 'ADMIN' && admin > 0">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div class="d-flex justify-center">
                  <v-card-title
                      style="color: burlywood;"
                      class="text-h5 d-flex"
                      v-text="item.title"
                  ></v-card-title>
                </div>
                <v-avatar
                    class="ma-3"
                    size="125"
                    tile
                >
                  <v-icon style="color: burlywood;" :size="item.size">
                    {{ item.icon }}
                  </v-icon>
                </v-avatar>
              </div>
            </router-link>

            <router-link :to="{ name: item.link }" style="text-decoration: none;" v-else-if="item.visible == 'GERAL' || admin > 0">
              <div class="d-flex flex-no-wrap justify-space-between">
                <div class="d-flex justify-center">
                  <v-card-title
                      style="color: burlywood;"
                      class="text-h5 d-flex"
                      v-text="item.title"
                  ></v-card-title>
                </div>
                <v-avatar
                    class="ma-3"
                    size="125"
                    tile
                >
                  <v-icon style="color: burlywood;" :size="item.size">
                    {{ item.icon }}
                  </v-icon>
                </v-avatar>
              </div>
            </router-link>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import session from "js-cookie";

export default {
  data: () => ({
    items: [
      {
        link: 'geral-candidato',
        title: 'PROCESSO SELETIVO',
        icon: 'mdi-account-hard-hat',
        size: 80,
        visible: 'ADMIN',
      },
      {
        link: 'geral-treinamento-professor',
        title: 'TREINAMENTO DOS PROFESSORES',
        icon: 'mdi-school',
        size: 80,
        visible: 'GERAL',
      },
      {
        link: 'geral-remuneracao-professor',
        title: 'REMUNERAÇÃO DOS PROFESSORES',
        icon: 'mdi-currency-usd',
        size: 80,
        visible: 'ADMIN',
      },
      {
        link: 'geral-relatorio',
        title: 'RELATÓRIOS',
        icon: 'mdi-file-cog',
        size: 65,
        visible: 'GERAL',
      },
    ],
    admin: session.get('_admin'),
  }),
}
</script>

<style scoped>

.card-geral{

  padding-top: 20px;
}

@media screen and (max-width: 730px) {

  .card-geral{

    padding-top: 0;
  }
}

@media screen and (max-width: 458px) {

  .card-geral{

    padding-top: 20px;
  }
}
</style>
