import jsPDF from "jspdf"
import 'jspdf-autotable'
import cabecalho from '@/relatorios/geral/header'
import footer from '@/relatorios/geral/footer'

export default {

    run(item){

        let header = []
        header.push(cabecalho.run())

        let relatorio = []

        relatorio.push([{ content: 'TREINAMENTO DO SISTEMA BETHA PROFESSORES - ' + (new Date()).getFullYear(), colSpan: 4, styles: { halign: 'center', fontStyle: 'bold' }}])
        relatorio.push([{ content: item.titulo, colSpan: 4, styles: { halign: 'center' }}])
        relatorio.push([{ content: item.subtitulo, colSpan: 4, styles: { halign: 'center', fontSize: 9 }}])

        relatorio.push(
            [
                { content: item.titulos[0], styles: { halign: 'center', fontStyle: 'bold', fontSize: 9 }},
                { content: item.titulos[1], styles: { halign: 'center', fontStyle: 'bold', fontSize: 9 }},
                { content: item.titulos[2], styles: { halign: 'center', fontStyle: 'bold', fontSize: 9 }},
                { content: item.titulos[3], styles: { halign: 'center', fontStyle: 'bold', cellWidth: 60, fontSize: 9 }},
            ])

        if(item.professores.length > 0){

            for(let i = 0; i < item.professores.length; i++){

                relatorio.push(
                    [
                        { content: item.professores[i]['id'], styles: { halign: 'center', fontSize: 9 }},
                        { content: item.professores[i]['nome'], styles: { halign: 'left', fontSize: 9 }},
                        { content: item.professores[i]['cpf'], styles: { halign: 'center', fontSize: 9 }},
                        { content: item.professores[i]['assinatura'], styles: { halign: 'left', valign: 'middle', fontSize: 9 }},
                    ])
            }
        }

        const doc = new jsPDF()

        //Tabela do cabeçalho
        doc.autoTable({theme: 'plain', body: header, didDrawCell: (data) => {
                if (data.section === 'body' && data.column.index === 0) {
                    var base64Img = cabecalho.logo()
                    doc.addImage(base64Img, 'JPEG', data.cell.x + 3, data.cell.y, 30, 20)
                }
            },})

        //Tabela do corpo
        doc.autoTable({theme: 'grid', body: relatorio })

        doc.autoTable({theme: 'plain', body: footer.Run()})

        doc.output('dataurlnewwindow')
    },
}
