<template>
  <div class="pa-5">
    <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="20"
        sort-by="nome"
        class="elevation-1"
        :search="search"
        style="padding-top: 20px"
        id="my-table"
    >
      <template v-slot:top>

        <v-toolbar flat class="mb-3">
          <v-text-field v-model="search" label="Search" class="mt-8" outlined append-icon="mdi-magnify"></v-text-field>
        </v-toolbar>

        <v-toolbar flat>

          <v-toolbar-title>
            <span>USUÁRIOS</span><br>
          </v-toolbar-title>

        </v-toolbar>

        <v-toolbar flat>
          <dialog-create-usuario-component
              :sendDialog="dialog"
              :sendEditedIndex="editedIndex"
              :sendEditedItem="editedItem"
              :sendModelsFormCreate="modelsFormCreate"
              :sendAdmin="admin"
              @returnDialog="dialog = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessage="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
              @returnAdmin="admin = $event"
          ></dialog-create-usuario-component>

          <dialog-delete-usuario-component
              :sendEditedIndex="editedIndex"
              :sendDialogDelete="dialogDelete"
              @returnDialogDelete="dialogDelete = $event"
              @returnEditedIndex="editedIndex = $event"
              @returnMessageDel="message = $event"
              @returnSnackbar="snackbar = $event"
              @returnInitialize="initialize"
          ></dialog-delete-usuario-component>
        </v-toolbar>

      </template>

      <template v-slot:[`item.actions`]="{ item }">

        <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        <v-icon small @click="deleteItem(item)" v-if="admin == 1">mdi-delete</v-icon>

      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

    </v-data-table>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>

    <loading-component :overlay="overlay"></loading-component>

  </div>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import DialogCreateUsuarioComponent from "@/components/geral/usuarios/DialogCreateUsuarioComponent.vue";
import DialogDeleteUsuarioComponent from "@/components/geral/usuarios/DialogDeleteUsuarioComponent.vue";
import session from "js-cookie";

export default {
  components: {
    LoadingComponent,
    SnackbarComponent,
    DialogCreateUsuarioComponent,
    DialogDeleteUsuarioComponent,
  },
  data: () => ({

    dialog: false,
    dialogDelete: false,
    search: '',
    snackbar: false,
    message: {sucesso: false, erro: false},
    overlay: false,
    headers: [
      { text: 'Nome', align: 'start', sortable: false, value: 'name' },
      { text: 'Email', align: 'start', sortable: false, value: 'email' },
      { text: 'Admin', align: 'start', sortable: false, value: 'adminText' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
    ],
    editedItem: {
      descricao: '',
    },
    defaultItem: {
      descricao: '',
    },
    desserts: [],
    editedIndex: 0,
    modelsFormCreate: [],
    nivelId: session.get('_nivel_id'),
    admin: session.get('_admin'),
  }),
  created() {

    this.initialize()
  },
  watch: {

    admin(val) { this.$emit('returnAdmin', val) }
  },
  methods: {

    initialize(){

      this.overlay = true

      model.apiGet('users').then(response => {

        this.overlay = false

        this.desserts = response.data.users
        this.modelsFormCreate = response.data.modelos
      })
    },
    editItem(item){

      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {

      this.editedIndex = item.id
      this.dialogDelete = true
    },
  },
}
</script>

<style scoped>

</style>
