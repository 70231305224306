<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }" v-if="admin == 1">
        <v-row>
          <v-col cols="12">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Relatório
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#informacoes-gerais">INFORMAÇÕES GERAIS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="informacoes-gerais" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="12">
                  <v-text-field
                      v-model="editedItem.descricao"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Descrição
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      v-model="editedItem.link"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Link
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-switch
                      v-model="editedItem.caixa_dialogo"
                      label="Caixa de diálogo"
                      color="indigo"
                      hide-details
                  ></v-switch>
                </v-col>

              </v-row>
            </v-tab-item>

          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";
import session from "js-cookie";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendModelsFormCreate',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      descricao: '',
      link: '',
      caixa_dialogo: false,
    },
    defaultItem: {
      descricao: '',
      link: '',
      caixa_dialogo: false,
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    admin: session.get('_admin'),
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Relatório'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) {this.editedItem = val },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        model.apiPut('relatorio/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        model.apiPost('relatorio/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
  },
}
</script>
