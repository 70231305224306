<template>
  <div>
    <v-toolbar flat v-if="!sessionDialog">
      <login-component
          @returnMessage="message = $event"
          @returnSnackbar="snackbar = $event"
          @returnInitialize="initialize"
      ></login-component>
    </v-toolbar>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>
  </div>
</template>

<script>

import SnackbarComponent from "@/components/snippets/SnackbarComponent";
import LoginComponent from "@/components/home/LoginComponent.vue";

import session from "js-cookie";

  export default {
    components: {
      LoginComponent,
      SnackbarComponent,
    },
    data: () => ({

      snackbar: false,
      message: {sucesso: false, erro: false},
      sessionDialog: session.get('_dialog')
    }),
    created() {

        this.initialize()
    },
    methods: {

      initialize(){

        //
      },
    },
  }
</script>
