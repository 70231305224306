import jsPDF from "jspdf"
import 'jspdf-autotable'
import cabecalho from '@/relatorios/geral/header'

export default {

   run(item){

       let header = []
       header.push(cabecalho.run())

       let relatorio = []

       relatorio.push([{ content: 'TREINAMENTO DO SISTEMA BETHA PROFESSORES - ' + (new Date()).getFullYear(), colSpan: 5, styles: { halign: 'center', fontStyle: 'bold'}}])
       relatorio.push([{ content: item.titulo, colSpan: 5, styles: { halign: 'center'}}])
       relatorio.push([{ content: item.subtitulo, colSpan: 5, styles: { halign: 'center', fontSize: 9}}])
       relatorio.push([{ content: item.periodo, colSpan: 5, styles: { halign: 'center', fontSize: 9, fontStyle: 'bold'}}])

       //MANHÃ
       relatorio.push([{ content: 'MANHÃ', colSpan: 5, styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }}])

       relatorio.push(
           [
               { content: (typeof item.dias[0] != 'undefined' ? item.dias[0].dia + ' - ' + item.dias[0].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[1] != 'undefined' ? item.dias[1].dia + ' - ' + item.dias[1].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[2] != 'undefined' ? item.dias[2].dia + ' - ' + item.dias[2].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[3] != 'undefined' ? item.dias[3].dia + ' - ' + item.dias[3].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[4] != 'undefined' ? item.dias[4].dia + ' - ' + item.dias[4].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
           ])

       for(let i = 0; i < 6; i++)
       {
           let item0 = (typeof item.dias[0] != 'undefined' ? item.dias[0].sigla : 'nulo')
           let item1 = (typeof item.dias[1] != 'undefined' ? item.dias[1].sigla : 'nulo')
           let item2 = (typeof item.dias[2] != 'undefined' ? item.dias[2].sigla : 'nulo')
           let item3 = (typeof item.dias[3] != 'undefined' ? item.dias[3].sigla : 'nulo')
           let item4 = (typeof item.dias[4] != 'undefined' ? item.dias[4].sigla : 'nulo')

           relatorio.push(
               [
                   { content: (typeof item.itens.mat[item0] != 'undefined' && typeof item.itens.mat[item0][i] != 'undefined' ? item.itens.mat[item0][i] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.mat[item1] != 'undefined' && typeof item.itens.mat[item1][i] != 'undefined' ? item.itens.mat[item1][i] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.mat[item2] != 'undefined' && typeof item.itens.mat[item2][i] != 'undefined' ? item.itens.mat[item2][i] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.mat[item3] != 'undefined' && typeof item.itens.mat[item3][i] != 'undefined' ? item.itens.mat[item3][i] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.mat[item4] != 'undefined' && typeof item.itens.mat[item4][i] != 'undefined' ? item.itens.mat[item4][i] : ''), styles: { halign: 'left', fontSize: 8 }},
               ])
       }

       //TARDE
       relatorio.push([{ content: 'TARDE', colSpan: 5, styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }}])

       relatorio.push(
           [
               { content: (typeof item.dias[0] != 'undefined' ? item.dias[0].dia + ' - ' + item.dias[0].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[1] != 'undefined' ? item.dias[1].dia + ' - ' + item.dias[1].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[2] != 'undefined' ? item.dias[2].dia + ' - ' + item.dias[2].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[3] != 'undefined' ? item.dias[3].dia + ' - ' + item.dias[3].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
               { content: (typeof item.dias[4] != 'undefined' ? item.dias[4].dia + ' - ' + item.dias[4].data : 'DIA NÃO LETIVO'), styles: { halign: 'center', fontSize: 9, fontStyle: 'bold', fillColor: [215, 215, 215] }},
           ])

       for(let j = 0; j < 6; j++)
       {
           let item0 = (typeof item.dias[0] != 'undefined' ? item.dias[0].sigla : 'nulo')
           let item1 = (typeof item.dias[1] != 'undefined' ? item.dias[1].sigla : 'nulo')
           let item2 = (typeof item.dias[2] != 'undefined' ? item.dias[2].sigla : 'nulo')
           let item3 = (typeof item.dias[3] != 'undefined' ? item.dias[3].sigla : 'nulo')
           let item4 = (typeof item.dias[4] != 'undefined' ? item.dias[4].sigla : 'nulo')

           relatorio.push(
               [
                   { content: (typeof item.itens.vesp[item0] != 'undefined' && typeof item.itens.vesp[item0][j] != 'undefined' ? item.itens.vesp[item0][j] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.vesp[item1] != 'undefined' && typeof item.itens.vesp[item1][j] != 'undefined' ? item.itens.vesp[item1][j] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.vesp[item2] != 'undefined' && typeof item.itens.vesp[item2][j] != 'undefined' ? item.itens.vesp[item2][j] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.vesp[item3] != 'undefined' && typeof item.itens.vesp[item3][j] != 'undefined' ? item.itens.vesp[item3][j] : ''), styles: { halign: 'left', fontSize: 8 }},
                   { content: (typeof item.itens.vesp[item4] != 'undefined' && typeof item.itens.vesp[item4][j] != 'undefined' ? item.itens.vesp[item4][j] : ''), styles: { halign: 'left', fontSize: 8 }},
               ])
       }

       const doc = new jsPDF('landscape')

       //Tabela do cabeçalho
       doc.autoTable({theme: 'plain', body: header, didDrawCell: (data) => {
               if (data.section === 'body' && data.column.index === 0) {
                   var base64Img = cabecalho.logo()
                   doc.addImage(base64Img, 'JPEG', data.cell.x + 3, data.cell.y, 30, 20)
               }
           },})

       //Tabela do corpo
       doc.autoTable({theme: 'grid', body: relatorio })

       doc.output('dataurlnewwindow')
   }
}
