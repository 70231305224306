<template>
  <v-app>
    <!--Navbar-->
    <nav class="navbar" v-if="sessionDialog" style="padding-left: 20px;">
      <!--logo-->
      <h1 class="navbar__logo">
        <router-link :to="{ name: 'home' }">NetSchools</router-link>
      </h1>
      <!--Menu-->
      <ul class="navbar__menu">

        <li class="navbar__item">
          <v-sheet>
            <router-link class="dropdown" :to="{ name: 'geral-menu' }">Menu</router-link>
          </v-sheet>
        </li>

      </ul>
    </nav>

    <v-row dense>
      <v-col cols="6">
        <h1 class="navbar__logo">
          <router-link :to="{ name: 'home' }">NetSchools</router-link>
        </h1>
      </v-col>
    </v-row>


    <v-main>
      <div class="menu-ano pl-10 pr-5 py-3" v-if="sessionDialog">
        <v-row>
          <v-col cols="10">
            <span style="color: #eeeeee;">
              <v-icon style="color: #eeeeee; font-size: 40px;">mdi-cast-education</v-icon>
              <span style="margin-left: 5px; font-size: 18px;">Educação {{ ano }}</span>
            </span>
          </v-col>
          <v-col cols="2">
            <v-menu
                bottom
                right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on"
                    style="float: right"
                >
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </template>

              <v-list>

                <v-list-item>
                  <v-list-item-title>
                    <v-icon style="font-size: 18px;">
                      mdi-account
                    </v-icon>
                    {{ usuario }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <v-icon style="font-size: 18px;">
                      mdi-account-check
                    </v-icon>
                    {{ nivel }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    <router-link :to="{ name: 'geral-usuario' }" style="text-decoration: none; color: #333;">
                      <v-icon style="font-size: 18px;">
                        mdi-account-cog
                      </v-icon>
                      Usuários
                    </router-link>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="logout">
                  <v-list-item-title>
                    <v-icon style="font-size: 18px;">
                      mdi-logout
                    </v-icon>
                    Sair
                  </v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>

      <div class="menu-escola pl-10 pr-5 py-3" v-if="sessionDialog">
        <v-row>
          <v-col cols="12">
            <span style="color: #eeeeee;">
              <span style="margin-left: 5px; font-size: 12px;">SECRETARIA MUNICIPAL DA EDUCAÇÃO</span>
            </span>
          </v-col>
        </v-row>
      </div>

      <v-navigation-drawer v-model="drawer" temporary class="navigation-drawer" style="position: fixed; width: 300px;" v-if="sessionDialog">

        <v-list-item style="position: fixed; background-color: white; z-index: 1; width: 100%; border-bottom: 1px solid #ccc;">

          <v-list-item-avatar>
            <v-img :src="require('@/assets/student.png')"></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title style="color: #888888;">{{ titleMenu }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

        <!--Itens do menu lateral-->
        <v-list dense class="alx-menu" style="position: relative; top: 65px;">

          <v-list-item v-for="item in itensMenu" :key="item.name">

            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

              <v-list-item-content style="padding: 0 !important;">
                <router-link :to="{ name: item.link }" style="text-decoration: none; color: #888; display: block; padding: 10px 0;" :title="item.title">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </router-link>
              </v-list-item-content>

          </v-list-item>

        </v-list>
        <!--Fim do menu lateral-->

      </v-navigation-drawer>

      <!--Navegação-->
      <router-view
          class="vmain"
          @returnAdmin="admin = $event"
          @returnAno="ano = $event"
      ></router-view>

    </v-main>

    <snackbar-component :sendSnackbar="snackbar" :message="message" @returnSnackbar="snackbar = $event"></snackbar-component>
  </v-app>
</template>

<script>

import model from "@/modulos/model"
import session from "js-cookie"
import SnackbarComponent from "@/components/snippets/SnackbarComponent"

export default {
  name: 'App',
  components: {
    SnackbarComponent,
  },
  data: () => ({
    drawer: null,
    titleMenu: '',
    itensMenu: {title: '', icon: '', link: ''},
    configurando: [
      { name: 'Anos letivos', icon: 'mdi-calendar-month', link: 'home', title: '' },
    ],
    administrando: [
      { name: 'Cursos', icon: 'mdi-book-education-outline', link: 'home', title: '' },
    ],
    planejando: [
      { name: 'Eventos', icon: 'mdi-calendar-multiple-check', link: 'home', title: '' },
    ],
    executando: [
      { name: 'Frequência', icon: 'mdi-calendar-check', link: 'home', title: '' },
    ],
    escola: '',
    items: [
      {title: 'Sair'},
    ],
    message: '',
    snackbar: false,
    sessionDialog: session.get('_dialog'),
    usuario: session.get('_usuario_nome'),
    nivel: session.get('_nivel_descricao'),
    admin: session.get('_admin'),
    ano: session.get('_ano')
  }),
  created() {

    this.initialize()
  },
  methods: {
    initialize(){

      //
    },
    toggleMenu(param){

      this.drawer = !this.drawer
      this.titleMenu = param

      if(param == 'CONFIGURANDO'){
        this.itensMenu = this.configurando
      }
      else if(param == 'ADMINISTRANDO'){

        this.itensMenu = this.administrando
      }
      else if(param == 'PLANEJANDO'){

        this.itensMenu = this.planejando
      }
      else if(param == 'EXECUTANDO'){

        this.itensMenu = this.executando
      }
    },
    logout(){

      model.apiLogout().then(response => {

        session.remove('_dialog')
        session.remove('_usuario_id')
        session.remove('_usuario_nome')
        session.remove('_nivel_id')
        session.remove('_nivel_descricao')
        session.remove('_admin')
        this.message = response
        this.snackbar = true

        this.$router.go(0);
      })
    }
  },
};
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body{
  font-family: "Open Sans", sans-serif;
}
.navbar{
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  border-bottom: 1px solid #eee;
  width: 100%;
  z-index: 1;
  background-color: white;
}
.navbar__logo a{
  text-decoration: none;
  color: #5a8396 !important;
  font-size: 26px;
}
.navbar__menu{
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.navbar__item{
  position: relative;
}
.navbar__item:not(:first-child){
  margin-left: 30px;
}
.navbar__item a{
  text-decoration: none;
  color: #5a8396 !important;
  font-size: 16px;
}
.submenu{
  z-index: 1;
  border-radius: 5px;
  position: absolute;
  top: calc(100% + 105px);
  max-width: max-content;
  right: 0px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
  transform: translateY(15%);
  opacity: 0;
  pointer-events: none;
  transition: transform .2s, opacity 0.3s;
  background-color: white;
}
.submenu.show{
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
}
.submenu .box-img{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 380px;
  object-fit: cover;
  margin-right: 5px;
  background-color: #eee;
}
.submenu__itens{
  padding: 20px;
  display: grid;
  grid-gap: 10px;
}
.menu-ano{
  background-color: #5a8396;
  width: 100%;
  position: fixed;
  top: 80px;
  z-index: 1;
}
.menu-escola{

  background-color: #5a8396;
  width: 100%;
  position: fixed;
  top: 145px;
  z-index: 1;
}
.vmain{

  position: relative; top: 150px;
}

@media screen and (max-width: 730px) {

  .navbar{

    padding-left: 0;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .navbar__menu{

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .navbar__item{

    margin-left: 0px !important;
    margin-bottom: 7px;
    border-bottom: solid 1px #cccccc;
  }
  .menu-ano{

    top: 215px;
  }
  .menu-escola{

    top: 280px;
  }
  .vmain{

    top: 200px;
  }
}

@media screen and (max-width: 458px) {

  .vmain{

    top: 300px;
  }
}
</style>
