<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="true"
    >

      <template v-slot:activator="{ on, attrs }">
        <v-row>
          <v-col cols="12">
            <v-btn color="success" dark class="ml-3" v-bind="attrs" v-on="on" large>
              <v-icon>mdi-plus</v-icon>
              Professor
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-card-title>
          <v-icon>mdi-calendar-month</v-icon>
          <span class="text-h5 ml-2" style="color: #666666;">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>

          <v-tabs
              v-model="tab"
              background-color="grey"
              center-active
              dark
          >
            <v-tab href="#dados-gerais">DADOS GERAIS</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="mt-8" style="height: 61vh; overflow-y: auto;">
            <v-tab-item value="dados-gerais" style="padding-top: 5px;">
              <v-row dense>

                <v-col cols="12" md="8">
                  <v-text-field
                      v-model="editedItem.nome"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Nome
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.cpf"
                      v-mask="'###.###.###-##'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Cpf
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.celular"
                      v-mask="'(##) #####-####'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Celular
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      v-model="editedItem.data_treinamento"
                      v-mask="'##/##/####'"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Data do treinamento
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete
                      v-model="editedItem.turno"
                      :items="modelsFormCreate.turnos"
                      item-value="id"
                      item-text="descricao"
                      outlined
                  >
                    <template #label>
                      <span class="red--text"><strong>* </strong></span>Turno
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="6">
                  <v-switch v-model="editedItem.ausente" label="Ausente" color="indigo" hide-details @change="changeAusente"></v-switch>
                </v-col>
                <v-col cols="6">
                  <v-switch v-model="editedItem.ativo" label="Ativo" color="indigo" hide-details></v-switch>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                      label="Motivo da ausência"
                      v-model="editedItem.motivo_ausencia"
                      outlined
                      :disabled="ausente ? false : true"
                  >
                    <template #label v-if="ausente">
                      <span class="red--text"><strong>* </strong></span>Motivo da ausência
                    </template>
                  </v-textarea>
                </v-col>

              </v-row>
            </v-tab-item>
          </v-tabs-items>

          <v-col cols="12" class="text-right">
            <v-btn color="red darken-1" text @click="close">
              Fechar
            </v-btn>
            <v-btn color="green darken-1" text @click="save">
              Salvar
            </v-btn>
          </v-col>

        </v-card-text>
      </v-card>

      <loading-component :overlay="overlay"></loading-component>

    </v-dialog>
  </v-row>
</template>

<script>

import model from "@/modulos/model";
import LoadingComponent from "@/components/snippets/LoadingComponent";

export default {
  components: {
    LoadingComponent,
  },
  props: [
    'sendDialog',
    'sendEditedIndex',
    'sendEditedItem',
    'sendModelsFormCreate',
  ],
  data: () => ({
    tab: null,
    dialog: false,
    message: '',
    editedItem: {
      nome: '',
      cpf: '',
      celular: '',
      data_treinamento: '',
      turno: '',
      ausente: false,
      motivo_ausencia: '',
      ativo: true,
    },
    defaultItem: {
      nome: '',
      cpf: '',
      celular: '',
      data_treinamento: '',
      turno: '',
      ausente: false,
      motivo_ausencia: '',
      ativo: true,
    },
    editedIndex: 0,
    overlay: false,
    modelsFormCreate: [],
    ausente: false,
  }),
  created(){

    //
  },
  computed: {

    formTitle () {

      return (this.editedIndex <= 0 ? 'Mais ' : ' Editar ') + ' Professor'
    },
  },
  watch: {

    sendDialog (val) { this.dialog = val },

    sendEditedIndex (val) { this.editedIndex = val },

    sendEditedItem (val) { this.editedItem = val; this.ausente = val.ausente },

    sendModelsFormCreate (val) { this.modelsFormCreate = val },
  },
  methods: {

    close(){

      this.dialog = false
      this.$emit('returnDialog', false)

      this.$nextTick(() => {

        this.editedItem = Object.assign({}, this.defaultItem)
        this.$emit('returnEditedIndex', 0)
        this.$emit('returnInitialize')
      })
    },
    save(){

      this.overlay = true

      if(this.editedIndex > 0){

        model.apiPut('treinamento-professor/update', this.editedIndex, this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response.data)
          this.$emit('returnSnackbar', true)

          if(typeof response.data.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
      else{

        model.apiPost('treinamento-professor/store', this.editedItem).then(response => {

          this.overlay = false

          this.message = response
          this.$emit('returnMessage', response)
          this.$emit('returnSnackbar', true)

          if(typeof response.sucesso != 'undefined'){

            this.$emit('returnInitialize')
            this.close()
          }
        })
      }
    },
    changeAusente(val){

      if(!val){

        this.editedItem.motivo_ausencia = '';
      }

      this.ausente = val
    },
  },
}
</script>
